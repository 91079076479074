<template>
    <div class="d-flex flex-column h-100 mt-8 align-items-start">
        <div class="d-flex flex-column flex-md-row justify-content-between w-100">
            <div class="text-left" >
                <h2>Escolha as categorias de seu interesse!</h2>
                <p v-html="text" class="paragraph-form"></p>
            </div>

            <div class="mt-4 mt-md-0">
                <v-btn :color="saved ? 'primary' : 'black'"
                        elevation="0"
                        :class="'mr-lg-8 py-5 px-8 ' + (saved ? 'avoid-click' : '')"
                        :width="!$widerThan('md', $vuetify.breakpoint.name) ? '100%' : '250'"
                        outlined
                        :disabled="!hasChange && !saved"
                        @click="handleSave">
                    <div v-if="!saving && !saved">
                        Salvar alterações
                    </div>

                    <div v-else>
                        <div v-if="!saved">
                            <v-progress-circular 
                                size="20" width="2"
                                indeterminate
                                class="mr-3"
                                color="black">
                            </v-progress-circular>
                            Salvando
                        </div>
                        <div v-else>
                            <v-icon class="mr-1"
                                    color="primary"
                                    id="icon-saved">
                                mdi-check
                            </v-icon>
                            Alterações salvas!
                        </div>
                    </div>
                </v-btn>
            </div>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-between w-100 mt-5">
            <div :class="saving ? 'w-100 avoid-click' : 'w-100'">
                <!--<CategoriesGroupSelect :pSelecteds="selecteds"
                                       :categories="categories"
                                       v-model="modelSelecteds"
                                       @input="saved = false"
                                       v-if="fetchedFavorites && fetchedCategories"></CategoriesGroupSelect>-->
                <CategoriesGroupAutocomplete :pSelecteds="selecteds"
                                       :categories="categories"
                                       v-model="modelSelecteds"
                                       @input="handleInput"
                                       v-if="fetchedFavorites && fetchedCategories"></CategoriesGroupAutocomplete>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    //import CategoriesGroupSelect from '../components/CategoriesGroupSelect.vue'
    import CategoriesGroupAutocomplete from '../components/CategoriesGroupAutocomplete.vue'

    export default {
        name: 'Categorias',

        components: {
            //CategoriesGroupSelect,
            CategoriesGroupAutocomplete,
        },

        data() {
            return ({
                saving: false,
                saved: false,
                categories: [],
                changed: false,
                fetchedFavorites: false,
                fetchedCategories: false,
                text: `Digite o nome da categoria ou selecione no menu abaixo.<br/>Enviaremos os informes das categorias selecionadas para seu e-mail.`,
                selecteds: [],
                modelSelecteds: [],
                styleButton: {
                    backgroundColor: this.$colors.categories,
                    color: this.$colors.grays.white,
                },
            })
        },

        methods: {
            handleInput: function () {
                this.saved = false;
            },
            handleSave: function () {
                if (this.hasChange) {
                    this.saving = true;

                    const user = this.$store.getters.authUser;
                    const [insertions, removals] = this.getDifference(this.modelSelecteds, this.selecteds)
                    const objPut = {
                        user_id: user.id,
                        insertions,
                        removals
                    }
                    //console.log(this.selecteds.map(x => x.title))
                    //console.log(this.modelSelecteds.map(x => x.title))
                    //console.log("insertions",objPut.insertions.map(x => x.title))
                    //console.log("removals",objPut.removals.map(x => x.title))

                    const token = this.$store.getters.authToken;
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

                    axios.put('/api/Home/UpdateFavoriteCategory', objPut)
                        .then(() => {
                            setTimeout(() => {
                                this.selecteds = [...this.modelSelecteds]
                                this.saving = false;
                                this.saved = true
                                this.changed = this.hasChange;
                            }, 1000)
                            
                        })
                        .catch(err => {
                            console.log(err)
                            this.saving = false;
                            this.saved = true
                        })
                }

            },

            fetchCategories: function () {
                const user = this.$store.getters.authUser;

                axios.get('/api/Home/GetCategories?id=' + user.id)
                    .then(resp => {
                        this.fetchedCategories = true;
                        this.categories = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            fetchUserFavorites: function () {
                const user = this.$store.getters.authUser;
                axios.get(`/api/Home/GetUsersPreferencesFromAPI?id=${user.id}`)
                    .then(resp => {
                        this.fetchedFavorites = true;
                        this.selecteds = resp.data;
                        this.modelSelecteds = [...this.selecteds];
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            getDifference: function (arr1, arr2) {
                const insertions = arr1.filter(x => !arr2.find(y => y.title == x.title));
                const removals = arr2.filter(y => !arr1.find(x => x.title == y.title));

                return [insertions, removals]
            },

        },

        created() {
            this.fetchCategories();
            this.fetchUserFavorites();
        },

        computed: {
            hasChange: function () {
                const [insertions, removals] = this.getDifference(this.modelSelecteds, this.selecteds);
                return insertions.length != 0 || removals.length != 0;
            },
        },

        /* eslint-disable */
        beforeRouteLeave(to, from, next) {
            if (this.hasChange) {
                const answer = window.confirm("Deseja realmente sair da página? Você ainda não salvou as alterações na suas categorias!\nClique em 'Salvar alterações' para salvá-las")
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            }
            else next()
        }
        /* eslint-enalbe */
    }
</script>

<style>

    #icon-saved {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.2s;
    }
</style>